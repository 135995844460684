import React from 'react'
import PropTypes from 'prop-types'
// import { css } from '@emotion/core'
// import { Link } from 'gatsby'
// import { Box, Row, Column } from './ui'

const Header = ({ routePath, siteTitle }) => {
  return <>{null}</>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
