import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Row } from './ui'
import Header from './header'

import './layout.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: 'calc(100vh - 0px)',
          backgroundColor: '#efefef',
        }}
      >
        <main
          css={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 999,
          }}
        >
          {children}
        </main>

        <Row justifyContent="center" py={2}>
          <Box color="#666" fontSize={10} fontWeight="bold">
            Copyright © {new Date().getFullYear()} | The Footsie Towel | All Rights Reserved
          </Box>
        </Row>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
