import React from 'react'
import { css } from '@emotion/core'
// import { Link } from 'gatsby'
// import { StaticQuery, graphql } from 'gatsby'
// import styled from '@emotion/styled'
// import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import { Box, Row } from '../components/ui'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Box width={1} maxWidth={500} mx="auto" mt={5}>
      <Image filename="logo.png" alt="The Footsie Towel" />
    </Box>

    <Box
      width={1}
      maxWidth={640}
      mx="auto"
      my={5}
      color="#555"
      textAlign="center"
      fontSize={36}
      fontWeight={300}
      css={{
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      <a href="https://store.thefootsietowel.com/">Click here to buy now!</a>
    </Box>

    <Row justifyContent="center" mt={3}>
      <Box
        css={css`
          /* embed from: cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css */
          /* MailChimp Form Embed Code - Horizontal Super Slim - 12/16/2015 v10.7
            Adapted from: http://blog.heyimcat.com/universal-signup-form/ */

          #mc_embed_signup form {
            text-align: center;
            padding: 10px 0 10px 0;
          }
          .mc-field-group {
            display: inline-block;
          } /* positions input field horizontally */
          #mc_embed_signup input.email {
            font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif;
            font-size: 15px;
            border: 1px solid #abb0b2;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            color: #343434;
            /* background-color: #fff; */
            box-sizing: border-box;
            height: 32px;
            padding: 0px 0.4em;
            display: inline-block;
            margin: 0;
            width: 350px;
            vertical-align: top;
          }
          #mc_embed_signup label {
            display: block;
            font-size: 16px;
            padding-bottom: 10px;
            font-weight: bold;
          }
          #mc_embed_signup .clear {
            display: inline-block;
          } /* positions button horizontally in line with input */
          #mc_embed_signup .button {
            font-size: 13px;
            border: none;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            letter-spacing: 0.03em;
            color: #fff;
            background-color: #aaa;
            box-sizing: border-box;
            height: 32px;
            line-height: 32px;
            padding: 0 18px;
            display: inline-block;
            margin: 0;
            transition: all 0.23s ease-in-out 0s;
          }
          #mc_embed_signup .button:hover {
            background-color: #777;
            cursor: pointer;
          }
          #mc_embed_signup div#mce-responses {
            float: left;
            top: -1.4em;
            padding: 0em 0.5em 0em 0.5em;
            overflow: hidden;
            width: 90%;
            margin: 0 5%;
            clear: both;
          }
          #mc_embed_signup div.response {
            margin: 1em 0;
            padding: 1em 0.5em 0.5em 0;
            font-weight: bold;
            float: left;
            top: -1.5em;
            z-index: 1;
            width: 80%;
          }
          #mc_embed_signup #mce-error-response {
            display: none;
          }
          #mc_embed_signup #mce-success-response {
            color: #529214;
            display: none;
          }
          #mc_embed_signup label.error {
            display: block;
            float: none;
            width: auto;
            margin-left: 1.05em;
            text-align: left;
            padding: 0.5em 0;
          }
          @media (max-width: 768px) {
            #mc_embed_signup input.email {
              width: 100%;
              margin-bottom: 5px;
            }
            #mc_embed_signup .clear {
              display: block;
              width: 100%;
            }
            #mc_embed_signup .button {
              width: 100%;
              margin: 0;
            }
          }
          #mc_embed_signup {
            /* background: #fff; */
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            width: 100%;
          }
        `}
      >
        <div id="mc_embed_signup">
          <form
            action="https://thefootsietowel.us3.list-manage.com/subscribe/post?u=2b0b8cc0035c221e27de7e09d&amp;id=6a95077e9b"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <input
                type="email"
                name="EMAIL"
                className="email"
                id="mce-EMAIL"
                placeholder="email address"
                required
              />
              <div
                aria-hidden="true"
                css={css`
                  position: absolute;
                  left: -5000px;
                `}
              >
                <input type="text" name="b_2b0b8cc0035c221e27de7e09d_6a95077e9b" tabIndex="-1" />
              </div>
              <div
                className="clear"
                css={css`
                  margin: 0 8px;
                `}
              >
                <input
                  type="submit"
                  value="Stay Informed"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                />
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Row>
  </Layout>
)

export default IndexPage
